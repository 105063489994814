import React from "react";
import { Card, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faClipboardCheck, 
  faComments,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons'

export default function Steps()  {
    
  return (
<Accordion className="cg-steps">
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="0">
       <h2><FontAwesomeIcon icon={faCalendarAlt} className='cg-stepIcon'/> 1. Pick a Date</h2>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <div>
        <Card.Body>
            Choose an install date from our calendar below <a href="#main" className="cg-linkDark" aria-label="Scroll Down"><FontAwesomeIcon icon={faArrowAltCircleDown} /></a>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">No deposit or obligation. This reserves your spot in our schedule.</small>
        </Card.Footer>
        </div>
    </Accordion.Collapse>
  </Card>
  
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="1">
      <h2><FontAwesomeIcon icon={faClipboardCheck} className='cg-stepIcon' /> 2. Pick Your Shed</h2>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
      <div>
        <Card.Body>Right after picking your date, you can pick your <strong>shed size</strong> and <strong>options</strong>.</Card.Body>
        <Card.Footer>
          <small className="text-muted">You can change your choices any time up to 3 days before the install</small>
        </Card.Footer>
      </div>
    </Accordion.Collapse>
  </Card>
  
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="2">
      <h2><FontAwesomeIcon icon={faComments} className='cg-stepIcon' /> 3. Finalize Details</h2>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
      <div>
        <Card.Body>We'll contact you a week before your install date to review your options and finalize details.</Card.Body>
        <Card.Footer>
          <small className="text-muted">You can cancel up to 3 days before the install.</small>
        </Card.Footer>
      </div>
    </Accordion.Collapse>
  </Card>
  
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="3">
      <h2><FontAwesomeIcon icon={faTruck} className='cg-stepIcon' /> 4. Install Day!</h2>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="3">
      <div>
        <Card.Body>
          <p>We'll arrive in the morning and build your shed on-site.</p>
          <p style={{width:'100%',textAlign:'center'}}><a href="#main" className="cg-linkDark"><FontAwesomeIcon icon={faArrowAltCircleDown} /> Lets Go! <FontAwesomeIcon icon={faArrowAltCircleDown} /></a></p>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">Most sheds are done in one day.</small>
        </Card.Footer>
      </div>
    </Accordion.Collapse>
  </Card>
  
</Accordion>
    )
}
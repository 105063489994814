import React from "react";
//import ContactForm from "../components/contactForm";
import SEO from "../components/seo";
import Header from "../components/header";
import Steps from "../components/steps";
import Layout from "../components/layout";
import SectionHeading from "../components/sectionHeading";
import { InlineWidget } from "react-calendly";
import { 
  Container, 
  Row, 
  Col, 
} from "react-bootstrap";

const Home = () => {

  // Docs: https://github.com/tcampb/react-calendly 
  const calUrl='https://calendly.com/utica-shed-pros/install';
  const calPageSettings={
    backgroundColor: 'f4f4f4',
    hideEventTypeDetails: true,
    hideLandingPageDetails: true,
    primaryColor: '00a2ff', // 00a2ff seems to be the only color of the free plan
    //primaryColor: '16c946', // Green is for "Go", not yellow ('e4b806') for "caution"
    //textColor: '172c50'
  }
  
  return (
  <Layout>

    {/* TODO new above fold component? */}
    <SEO 
      title='Book Your Shed'
      description='Schedule your shed install date'
      canonical='schedule'
    />
    <Header active='schedule'/>
    {/* END new above fold */}
    
    <div className='cg-sectionLight'>
      <Container fluid>
        <Row>
          <Col>
            <SectionHeading>Book Your Shed</SectionHeading>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>It’s easy to get your shed. (Tap for details):</p>
            <Steps/>
          </Col>
        </Row>
      </Container>
    </div>
    
    <main id='main'>
      <div className='cg-sectionLight'>
        <Container fluid>
          <Row>
            <Col>
              <SectionHeading>Pick Date & Options</SectionHeading>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Pick &amp; confirm an <span style={{fontWeight:700,color:`#${calPageSettings.primaryColor}`}}>available date</span>, then pick shed options:</p>
            {/* Docs: https://github.com/tcampb/react-calendly */}
              <InlineWidget 
                url={calUrl} 
                pageSettings={calPageSettings}
                style={{minWidth:320, height:630}}
                />
            </Col>
          </Row>
        </Container>
      </div>
      
      
      
    </main>
    {/* // Omit contactForm on scheduling page for better customer focus
    <Hr/>
    <ContactForm/>
    */}
  </Layout>
)};

export default Home;
